import React from 'react'
import ReactPlayer from 'react-player'

import Layout from '../../components/layout'
import LightBox from '../../components/lightbox/lightbox'

import p1 from '../../images/products/HR Reporting/Abacus Data Analyser HR Dashboard.jpg'
import p2 from '../../images/products/HR Reporting/Abacus Data Analyser HR Dashboard Zeit.jpg'
import p3 from '../../images/products/HR Reporting/Abacus Data Analyser HR Dashboard Saläranalyse.jpg'
import p4 from '../../images/products/HR Reporting/Abacus Data Analyser HR Dashboard HR Kennzahlen.jpg'

const hrreporting = () => (
    <Layout>
        <section id="hrreporting" className="section-wrapper">
            <header className="section-header">
                <h3>HR Reporting</h3>
            </header>

            <p>«Wir machen aus Daten Informationen»</p>
            <p>
                Personalabteilungen sind Lieferanten von Auswertungen, die bei wichtigen Geschäftsentscheiden miteinbezogen werden. Die Qualität dieser Informationen
                prägt bleibend den Ruf einer gesamten Personalabteilung. Erschwerend für das HR kommt oft hinzu, dass komplexe, präzise Informationen innerhalb kurzer
                Frist bereitgestellt werden müssen.
            </p>
            <p>Dieser Informationsbedarf verlangt nach einem durchdachten Auswertungskonzept.</p>
            <p>
                Wir ermöglichen schnellen und einfachen Zugriff auf Daten und liefern somit Informationen. Wir realisieren für unsere Kunden effizientes,
                kundenspezifisches Reporting z.B. in den Bereichen Personalkennzahlen (Fluktuation, Lohnvergleiche, FTE und Headcounts,…), Organisation,
                Ausbildungen und Mitarbeitergespräche.
            </p>
            <LightBox images={images} thumbnailHeight={80} />
            <div className="post section-with-vid" id="video">
                <div className="vid">
                    <ReactPlayer url="https://youtu.be/bcjbjbdyW4Q" controls={true} height="100%" width="100%" />
                </div>
            </div>

            <div className="post section-with-vid" id="video1">
                <div className="vid">
                    <ReactPlayer url="https://youtu.be/hLybsuEBN30" controls={true} height="100%" width="100%" />
                </div>
            </div>

        </section>
    </Layout>
)

export default hrreporting

const images = [
    {
        index: 0,
        src: p1,
        title: 'Hauptmenü',
        description: 'Hauptmenü aller HR Dashboards innerhalb des HR Portals'
    },
    {
        index: 1,
        src: p2,
        title: 'Dashboard Zeitmanagement',
        description: 'HR Dashboard für Zeitmanagement'
    },
    {
        index: 2,
        src: p3,
        title: 'Dashboard Saläranalyse',
        description: 'HR Dashboard für Saläranalyse'
    },
    {
        index: 3,
        src: p4,
        title: 'Dashboard Kennzahlen',
        description: 'HR Dashboard für HR Kennzahlen'
    },
]